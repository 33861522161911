.aboutUs {
  margin-top: 100px;
}

.aboutHero {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #155f98;
}

.apContentSec {
  display: flex;
  flex-direction: column;
  padding: 20px 100px;
}

.contentItem {
  /* background-image: linear-gradient(50deg, #480227 49%, #a31e7d 100%); */
  background-image: linear-gradient(50deg, #567BBC 49%, #424c5f 100%);
  margin: 5px 0;
  padding: 30px;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 65%);
}

.contentItem h2 {
  padding-bottom: 0;
  border-bottom: none;
}

.contentItem p {
  font-size: 16px;
}

.midHeading h2 {
  display: flex;
  justify-content: center;
  color: #c10e0e;
  font-size: 40px !important;
  padding: 10px;
}

.hlHeading {
  display: contents;
  /* color: #354256; */
  color: #2b70da;
}

.apInvite {
  /* background-color: #2e343c; */
  background-image: linear-gradient(178deg, #260248 49%, #4a1ea3 100%);
  color: #fff;
  padding: 30px;
  font-size: 24px;
  text-align: left;
  line-height: 1.4;
}

.quote-btn.abtBtn {
  right: 0;
  background-color: #fff;
  color: #262d38;
  font-weight: 600;
}

@media only screen and (max-width: 1024px) {
  .apContentSec {
    padding: 20px 100px;
  }

  .apInvite {
    font-size: 20px;
    line-height: 1.3;
    padding: 20px;
  }

  .midHeading h2 {
    font-size: 34px !important;
  }

  .contentItem p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 786px) {
  .apContentSec {
    padding: 20px 80px;
  }

  .apInvite {
    font-size: 18px;
  }

  .contentItem p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 430px) {
  .apContentSec {
    padding: 20px 20px;
  }

  .midHeading h2 {
    font-size: 24px !important;
  }

  .apInvite {
    font-size: 14px;
  }
}