.singleInsuranceService {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  padding: 0 100px;
}

.siSHero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.sishImg {
  height: 50vh;
}

.iSImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.sishTtitle {
  /* position: absolute;
  top: 20%; */
  font-size: 40px;
  margin-bottom: 0;
  padding-bottom: 5px;
  color: #0f4d92;
  /* border-bottom: 3px solid red; */
}

.siSHeroDesc h3,
.ourAppraoch h3 {
  font-weight: 500;
}

/* .insTContent {
  padding: 20px 100px;
} */

.siSHeroDesc {
  width: 50%;
}

/* .siSHeroDesc h3 {
  font-size: 24px;
  color: #666666;
} */

.ourAppraoch {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 20px 0;
  /* background: antiquewhite; */
  flex-direction: column;
}

.ourAppraoch h2 {
  color: #b62d2d;
  margin: 0;
  border-bottom: none;
  padding-bottom: 0;
  /* padding-left: 5px; */
  /* border-left: 3px solid red; */
  font-size: 36px;
}

.whyChooseInsurance {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}

.whyChooseInsurance h2 {
  font-size: 36px;
  align-self: center;
  width: fit-content;
}

.section {
  display: flex;
  align-items: center;
  margin: 20px 0;
  justify-content: space-between;
}

.heading {
  /* background-image: linear-gradient(50deg, #480227 49%, #a31e7d 100%); */
  background-image: linear-gradient(50deg, #567BBC 49%, #424c5f 100%);
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  width: 25%;
  border-radius: 10px;
  box-shadow: 0px 0px 28px -17px rgba(1.999999999999993, 16.00000000000007, 72, 0.56);
}

.heading h3 {
  margin: 0;
  color: white;
  font-size: 20px;
  text-align: center;
}

.content {
  padding: 20px;
  background-color: #f4f4f4;
  /* transform: skew(333deg); */
  width: 68%;
  box-shadow: 0px 0px 28px -17px rgba(1.999999999999993, 16.00000000000007, 72, 0.56);
  border-radius: 10px;
}

.content p {
  /* transform: skew(19deg); */
  font-size: 16px;
}

.sContent {
  padding: 20px;
  background-color: #f4f4f4;
  box-shadow: 0px 0px 28px -17px rgba(1.999999999999993, 16.00000000000007, 72, 0.56);
  border-radius: 10px;
}

.subServices h3 {
  color: #155f98;
}

@media (max-width: 1024px) {
  .singleInsuranceService {
    padding: 0 80px;
  }

  .ourAppraoch h2 {
    font-size: 28px !important;
  }

  .sishTtitle {
    font-size: 34px;
  }

  .siSHeroDesc h3,
  .ourAppraoch h3 {
    font-size: 18px;
  }

  .whyChooseInsurance h2 {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .singleInsuranceService {
    padding: 0 60px;
    margin-top: 70px;
  }

  .siSHero {
    padding: 20px 0;
  }

  .ourAppraoch {
    padding: 0 80px;
  }

  .siSHero {
    flex-direction: column;
  }

  .ourAppraoch h2 {
    font-size: 24px !important;
  }

  .siSHeroDesc {
    width: auto;
  }

  .siSHeroDesc h3,
  .ourAppraoch h3 {
    font-size: 16px;
  }

  .whyChooseInsurance {
    padding: 20px 0;
  }

  .whyChooseInsurance h2 {
    font-size: 22px;
  }

  .heading h3 {
    font-size: 16px;
  }
}

@media (max-width: 430px) {
  .singleInsuranceService {
    padding: 0 20px;
  }

  .sishImg {
    height: auto;
    margin-top: 10px;
  }

  .siSHero,
  .ourAppraoch {
    padding: 10px 0;
  }

  .section {
    flex-direction: column;
  }

  .heading {
    width: fit-content;
  }

  .siSHeroDesc h3 {
    /* text-align: center; */
    /* font-size: 1.2rem; */
    margin-bottom: 0;
  }

  .sishTtitle {
    font-size: 32px;
  }

  .ourAppraoch h2 {
    font-size: 18px !important;
  }

  .whyChooseInsurance h2 {
    font-size: 24px;
    text-align: center;
    margin-top: 0;
  }
}