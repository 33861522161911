.services {
  padding: 0 100px;
  margin-top: 100px;
}

.servicesTitile {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #155f98;
}

.insuranceServices {
  /* margin: 50px 0; */
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
  /* height: 100dvh; */
  position: relative;
  background-color: #fff;
}

.insuranceServices h3 {
  line-height: 1.8;
  text-align: center;
}

.insuranceTypesContainer {
  display: flex;
  flex-direction: column;
  /* box-shadow: 4px -2px 10px 9px rgba(0, 0, 0, 0.1); */
  margin: 20px 0;
  border-radius: 10px;
  /* padding: 25px; */
}

.insuranceType {
  display: flex;
  width: 100%;
  align-self: flex-start;
  justify-content: space-between;
  /* height: 230px; */
  /* gap: 30px; */
  margin: 50px 0;
  /* box-shadow: 8px 20px 15px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
  background-image: linear-gradient(50deg, #567BBC 49%, #424c5f 100%);
  padding: 20px;
}

.insuranceDesc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  /* flex: 2; */
  width: 45%;
}

.insuranceDesc h3 {
  font-weight: 700;
  font-size: 24px;
  margin: 0;
  /* color: #0f4d92; */
  color: #ffff00;
  display: flex;
  align-items: center;
}

.insuranceDesc p {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  text-align: justify;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
}

.link {
  text-decoration: none;
  color: inherit;
  width: fit-content;
}

.seeMore-btn {
  font-style: italic;
  margin: 0;
}

.seeMore-btn:hover {
  text-decoration: underline;
}

.insuranceTypeImage {
  /* flex: 1; */
  display: flex;
  /* justify-content: center; */
  /* justify-content: space-around; */
  width: 46%;
  height: 250px;
  position: relative;
  overflow: hidden;
}

.insuranceTypeImage img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.insuranceType:nth-child(odd) .insuranceDesc {
  order: 1;
}

.insuranceType:nth-child(odd) .insuranceTypeImage {
  order: 2;
}

.insuranceType:nth-child(even) .insuranceDesc {
  order: 2;
}

.insuranceType:nth-child(even) .insuranceTypeImage {
  order: 1;
}

.highlightedInsurance {
  position: relative;
  width: 100%;
  /* height: 40vh; */
  overflow: hidden;
  border-radius: 10px;
}

.HighlightedBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url(../../assets//highlitedInsuranceBg.jpg); */
  background-size: cover;
  background-position: bottom;
  filter: blur(5px);
  /* Adjust the blur amount */
}

.highInsuContent {
  position: relative;
  z-index: 1;
  /* Ensure the content is on top of the background */
  padding: 50px;
  color: white;
}

.highInsuContent h2 {
  font-size: 40px;
  text-shadow: 1px 1px 10px #000000;
  color: #fff;
}

.highInsuContent h4 {
  font-size: 20px;
  font-weight: 500;
  text-shadow: 1px 1px 10px #000000;
  line-height: 1.5;
}

@media (max-width: 1024px) {
  .insuranceType {
    align-items: center;
  }
}

@media (max-width: 768px) {
  .services {
    padding: 0 60px;
  }

  .quote-btn.insurance {
    right: 0;
  }
}

@media (max-width: 430px) {
  .services {
    padding: 0 20px;
  }

  .insuranceServices {
    height: auto;
    padding: 20px 20px;
  }

  .insuranceType {
    flex-direction: column;
    margin: 15px 0;
    padding: 10px 0;
  }

  .insuranceDesc,
  .insuranceTypeImage {
    width: auto;
  }

  .insuranceDesc {
    order: 2 !important;
  }

  .insuranceTypeImage {
    order: 1 !important;
  }

  .highInsuContent {
    padding: 10px 20px;
  }

  .highInsuContent h4 {
    font-size: 16px;
  }
}