.homePage {
  margin-top: 100px;
  padding: 0 100px;
}

.homeHero {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}

.heroLeft {
  width: 50%;
}

.heroRight {
  width: 45%;
}

.heroIcons {
  display: flex;
  justify-content: space-around;
}

.heroTitle h1 {
  color: #b62d2d;
  ;
  font-family: "Poppins", Sans-serif;
  font-size: 40px;
  font-weight: 800;
  line-height: 49px;
  /* width: 90%; */
  margin-bottom: 0;
  /* background: #ffff00; */
  text-align: center;
  padding: 10px;
  /* border-radius: 10px; */
}

.heroTitle p {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4em;
}

.contact-btn {
  font-family: "Poppins", Sans-serif;
  font-weight: 600;
  color: #FFFFFF;
  background-color: #33B454;
  /* background-color: #ffff00; */
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #33B454;
  color: #fff;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 7px 7px 0px rgb(51 180 99 / 43%);
  padding: 19px 23px 19px 23px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.contact-btn:hover {
  transform: translateY(-3px);
}

.heroImg {
  height: 450px;
  width: 500px;
}

.heroImg img {
  width: 100%;
  height: 100%;
}

.partnerIcons {
  display: flex;
  margin-top: 85px;
  width: 100%;
  margin-top: 40px;
}

.heroImgSwiper {
  display: flex;
  width: 100%;
}

.pIcon {
  display: flex;
  width: fit-content;
}

.icon {
  display: flex;
  /* width: fit-content; */
  width: 60px;
  height: 60px;
}

.icon img {
  width: 100%;
  height: 100%;
}

.singleIcon {
  display: flex;
  justify-content: center;
}

.elevateBrand {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}

.ebContent {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.ebcLogo {
  background-color: #33B454;
  /* background-color: #ffff00; */
  color: #fff;
  border-radius: 8px;
  padding: 10px;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.ebContent h2 {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: 0;
}

.ebContent p {
  font-size: 15px;
  margin-bottom: 0;
  text-align: justify;
}

.ebImg {
  width: 50%;
  height: 80vh;
  display: flex;
}

.ebImg img {
  width: 80%;
  align-self: center;
}

.experience {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 0;
  /* padding: 0 25%; */
}

.expContent {
  display: flex;
  align-items: center;
  /* width: 235px; */
  gap: 10px;
}

.chk {
  display: flex;
  background: #33B454;
  color: #fff;
  padding: 5px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

#chkIcon {
  font-size: 25px;
}

.whyChooseUs {
  /* background-image: linear-gradient(50deg, #480227 49%, #a31e7d 100%); */
  background-image: linear-gradient(50deg, #567BBC 49%, #424c5f 100%);
  padding: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  margin: 25px 0;
  flex-direction: column;
  gap: 25px;
}

.wcuContent {
  display: flex;
  flex-direction: column;
  /* width: 45%; */
  color: #fff;
  /* color: #000; */
}

.wcuContent h3 {
  /* color: #FF4969; */
  color: #ffff00;
  text-transform: uppercase;
}

.wcuContent h2 {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2em;
  margin: 0;
}

.wcuContent p {
  font-size: 15px;
  margin-bottom: 0;
}

.wcuIcons {
  display: flex;
  order: 2;
  justify-content: space-between;
}

.wcuTcon {
  width: 165px;
}

.wcuTcon img {
  width: 100%;
  border-radius: 10px;
}

.ourServices {
  margin: 25px 0;
}

.ourServicesTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ourServicesTitle h3 {
  color: #155f98;
}

.ourServicesTitle h2 {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2em;
  margin-top: 0;
}

.wcuList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  justify-content: center;
}

.wcuListItem {
  background-color: #fff;
  color: #212833;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  align-items: center;
  box-shadow: 0 5px 10px 0 rgb(210 210 245 / 50%);
}

.wcuListItem h4 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  align-self: center;
  text-align: center;
  height: 45px;
}

.wcuListItem p {
  font-size: 16px;
  text-align: center;
}

.quickSupport {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}

.qsContent {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.qsContent h3 {
  color: #155f98;
}

.qsContent h2 {
  margin: 0;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2em;
}

.qsOptions {
  display: flex;
}

.qsOption {
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 10px;
}

.qsOption h3 {
  margin-bottom: 0;
  color: #000;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.2em;
  text-shadow: none;
}

.qsoIcon {
  display: flex;
  background-color: #33B454;
  width: fit-content;
  border-radius: 50%;
  padding: 10px;
}

.qsoIcon.mail {
  background-color: #FF496963;
}

.qsForm {
  width: 45%;
}

.qSForm {
  margin-top: 20px;
  padding: 30px;
  width: 70%;
  box-shadow: 0px 0px 28px -17px rgba(1.999999999999993, 16.00000000000007, 72, 0.56);
  border-radius: 20px;
}

.qSForm span {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2em;
}

.inputFields {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.inputFields input {
  padding: 10px;
  border: 1px solid grey;
  border-radius: 5px;
}

.qSForm textarea {
  width: 95%;
  padding: 10px;
  border: 1px solid #80808057;
  border-radius: 5px;
}

input::placeholder,
textarea::placeholder {
  font-family: 'Poppins', sans-serif;
}

.getStartedBtn {
  background-color: #567BBC;
  /* background-color: #ffff00; */
  color: #fff;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 17px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-top: 10px;
}

.testimonial {
  display: flex;
  height: auto;
  justify-content: space-evenly;
  flex-direction: column;
  color: #000;
  position: relative;
  margin: 25px 0;
}

.testimonialDesc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial h3 {
  display: flex;
  justify-content: center;
  margin: 0;
  color: #155f98;
}

.testimonial h2 {
  display: flex;
  justify-content: center;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2em;
  margin-top: 0;
  text-align: center;
}

.testimonial-container {
  display: flex;
}

.testimonail-contnet {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #567BBC;
  box-shadow: 1px 1px 10px #000000;
  /* height: 200px; */
}

.people-quotes {
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  padding: 15px;
}

.quote-icon {
  background-color: bisque;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 25px;
  align-self: center;
}

.quote p {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  text-align: center;
}

.person-detail h4 {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0;
}

@media (max-width: 1024px) {
  .homePage {
    padding: 0 70px;
  }

  .homeHero {
    padding-top: 50px;
    flex-direction: column;
    text-align: center;
  }

  .heroTitle h1 {
    width: 90%;
  }

  .heroTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .heroLeft,
  .heroRight {
    width: 100%;
  }

  .heroRight {
    order: 2;
  }

  .heroIcons {
    justify-content: center;
  }

  .heroImg {
    margin-top: 10px;
    height: auto;
    width: auto;
  }

  .elevateBrand {
    flex-direction: column;
    text-align: center;
  }

  .ebContent {
    width: 100%;
  }

  .ebContent p {
    text-align: center;
  }

  .ebcLogo {
    align-self: center;
  }

  .experience {
    align-self: center;
  }

  .ebImg {
    align-self: center;
  }

  .wcuContent h2 {
    font-size: 30px;
  }

  .wcuIcons {
    top: 0;
    width: 90%;
    align-self: center;
  }

  .wcuTcon {
    width: 100px;
  }

  .wcuList {
    grid-template-columns: repeat(2, 1fr);
  }

  .quickSupport {
    flex-direction: column;
  }

  .qsContent {
    width: 100%;
  }

  .qsForm {
    width: 100%;
  }

  .qSForm {
    width: auto;
  }

  .inputFields input {
    width: 375px;
  }

  .qSForm textarea {
    width: 98%;
  }
}

@media (max-width: 768px) {
  .homePage {
    padding: 0 60px;
  }

  .wcuContent h2 {
    font-size: 28px;
  }

  .wcuTcon {
    width: 75px;
  }

  .ebImg {
    height: auto;
  }

  .experience {
    padding: 0 15%;
  }
}

@media (max-width: 430px) {
  .homePage {
    padding: 0 10px;
  }

  .homeHero {
    padding-top: 20px;
  }

  .heroIcons {
    display: none;
  }

  .heroTitle h1 {
    font-size: 24px;
    line-height: 33px;
  }

  .partnerIcons {
    margin-top: 0;
  }

  .ebcLogo {
    font-size: 30px;
  }

  .ebContent h2 {
    font-size: 28px;
  }

  .wcuTcon {
    width: 50px;
  }

  .wcuList {
    grid-template-columns: auto;
  }

  .experience {
    grid-gap: 10px 10px;
    grid-template-columns: auto;
    justify-content: center;
  }

  .expContent {
    display: flex;
    width: 100%;
    gap: 25px;
  }

  .whyChooseUs {
    padding: 20px;
  }

  .wcuContent {
    text-align: center;
  }

  .inputFields {
    flex-direction: column;
    gap: 10px;
  }

  .inputFields input {
    width: auto;
  }

  .qSForm textarea {
    width: 94%;
  }
}
